<template>
  <main 
    :class="classes">
    <div class="container">
      <!-- Header -->
      <header class="stream__header">
        <navigations-breadcrumb 
          class="view-stream-video__breadcrumb"
          :children="breadcrumbs" 
        />
      </header>

      <div class="view-stream-video__section">
        <div 
          class="view-stream-video__loader" 
          v-if="loading">
          <ui-loader />
        </div>

        <div id="video-player" class="view-stream-video__player" v-else>
          <video id="video-player-id" class="video-js"></video>
        </div>

        <div class="view-stream-video__header" v-if="!loading">
          <div class="view-stream-video__texts">
            <h2 class="stream__subtitle">{{ video.title }}</h2>
            <div class="ql-editor stream__subdescription" v-html="video.description"></div>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { nextTick } from 'vue'
import videojs from 'video.js'

import NavigationsBreadcrumb from '@/components/navigations/breadcrumb'

export default {
  name: 'StreamVideo',

  components: {
    NavigationsBreadcrumb
  },

  inject: [
    '$user',
    '$video'
  ],
  
  mixins: [ 
    
  ],

  data() {
    return {
      loading: true,
      player: null,
      keepalive: null
    }
  },

  computed: {
    breadcrumbs() {
      return [
        { label: this.$t('user-portal.user_account_title'), href: { name: 'sayl-user-portal.home' }},
        { label:  this.$t('user-portal.user_wallet_title'), href: { name: 'sayl-user-portal.wallet' } },
        { label: !this.loading ? this.video.title : this.$t('user-portal.loading'), href: '#' }
      ]
    },

    classes() {
      return {
        'layout-stream__body': true,
        '-is-loading': this.loading
      }
    },

    playerOptions() {
      return {
        autoplay: false,
        controls: true,
        poster: this.video.poster,
        fluid: false,
        html5: {
          vhs: {
            withCredentials: true
          }
        }
      }
    },

    user() {
      return this.$user.user || null
    },

    video() {
      return this.$video.video
    }
  },

  methods: {
    initPlayer(src) {
      this.player.src({
        type: 'application/x-mpegURL',
        src
      })
      window.addEventListener('resize', this.resize)
    },

    async reset() {
      try {
        this.loading = true
        await this.view({ triggerId: this.$route.params.triggerId })
        this.loading = false
        nextTick(() => {
          this.player = videojs('video-player-id', this.playerOptions)
          this.player.on('ready', () => this.initPlayer(this.video.url))
          this.player.on('loadedmetadata', () => this.resize())
        })
        this.keepalive = setInterval(() => {
          this.$video.keepalive({ videoId: this.video.id })
        }, 5 * 60 * 1000);
      } catch(e) {
        console.error(e)
        this.loading = false
      }
    },

    resize() {
      const player = document.querySelector('.video-js')
      const body = document.querySelector('body')

      if (player && body) {
        const bodyWidth = body.getBoundingClientRect().width
        if (!player.classList.contains('fit-height')) {
          player.classList.add('fit-height')
        }

        if (body.scrollWidth > bodyWidth) {
          player.classList.remove('fit-height')
        }
      }
    },

    async view(payload) {
      return await this.$video.view(payload)
    }
  },

  mounted() {
    this.reset()
  },

  beforeDestroy() {
    if (this.player) {
      this.player.dispose()
      window.removeEventListener('resize', this.resize)
    }

    if (this.keepalive) {
      clearInterval(this.keepalive)
    }
  }
}
</script>